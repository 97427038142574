<div class="w-full flex flex-col gap-1">
  <label *ngIf="props['parentQuestion']" class="text-black mb-6"
  >{{props['parentQuestion']}} <span *ngIf="props.required" class="text-primary-magenta">*</span></label>
  <label class="text-black"
  >{{props.label}}</label>
  <div>
    <mat-radio-group [formControl]="formControl" [formlyAttributes]="field">
      <mat-radio-button [value]="number" *ngFor="let number of numbers">
        {{number}}</mat-radio-button>
    </mat-radio-group>
    <div class="!h-5">
      <mat-error class="h-full" *ngIf="formControl.hasError('required') && formControl.touched">
        {{ 'error.field.required' | translate }}
      </mat-error>
    </div>
  </div>
</div>
