import {Component, Input} from '@angular/core';
import {AuthService} from "@base";
import {TranslateService} from "@ngx-translate/core";

import {AnnouncementDto} from "@api/models/announcement-dto";
import {parse} from "date-fns";
import {UserDetailPopupViewComponent} from '@feed/components';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'gds-feed-announcement',
  templateUrl: './feed-announcement.component.html',
  styleUrl: './feed-announcement.component.scss'
})
export class FeedAnnouncementComponent {
  @Input({required: true}) announcement!: AnnouncementDto;
  @Input() availableDate!: Date;

  constructor(
      public authService: AuthService,
      public translateService: TranslateService,
      public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {

  }

  showInfo() {
    this.dialog.open(UserDetailPopupViewComponent,{data: this.announcement.createdUser});
  }

   changeToLinks(inputText : string) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a class="underline" href="$1" target="_blank">$1</a>');

    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" class="underline"  target="_blank">$2</a>');

    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a class="underline"  href="mailto:$1">$1</a>');

    return replacedText;
  }
}
