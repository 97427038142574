<div
        class="flex flex-col gap-6"
>
  <ng-container *ngFor="let feed of feeds">
    <gds-feed-announcement *ngIf="isAnnouncement(feed)" [announcement]="getAnnouncement(feed)"
                           [availableDate]="getAvailableDate(feed)">
    </gds-feed-announcement>
    <gds-feed-log-entry (itemDeletedEvent)="deleteItem($event)" *ngIf="!isAnnouncement(feed)" [form]="getForm(feed)" [user]="getUser(feed)" [currentUser]="(authService.user$ | async)"
                        [availableDate]="getAvailableDate(feed)">
    </gds-feed-log-entry>
  </ng-container>
</div>
