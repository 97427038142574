<mat-card>
    <mat-card-content>
        <div class="flex gap-4 items-center flex-col">
            <div class="flex items-center justify-between w-full">
                <div class="flex gap-2 items-center cursor-pointer" (click)="showInfo()">
                    <img
                            [src]="user.picture??'/assets/default_avatar.png'"
                            alt="Avatar"
                            class="w-8 h-8 rounded-full"
                    />
                    <span class="text-black font-bold">
          {{user!.firstName}}
        </span>
                </div>
                <div class="flex gap-2 items-center justify-center">
                    <span class="text-graytone-middle">{{getFeedInfo()}}</span>
                    <a class="cursor-pointer" (click)="delete()" *ngIf="isDeletable() && this.isCurrentUser">
                        <mat-icon svgIcon="gds-delete-feed"></mat-icon>
                    </a>
                </div>
            </div>

            <div class="flex flex-col w-full">
                <div class="text-base font-bold">
                    <span>{{form.formTemplate.badgeName}}</span><span> - {{form.formTemplate.name}}</span><span
                        *ngIf="form.provider"> - {{form.provider}}</span>
                </div>
                <div class="flex gap-4 items-start justify-between w-full">
                    <div class="text-primary-darkblue italic">
                        <p style="overflow-wrap: anywhere">"{{form.rate.comment}}"</p>
                    </div>
                    <div class="flex gap-2 items-center">
                        <ng-container *ngFor="let rate of ratingArr; index as i">
                            <mat-icon *ngIf="selectedRating(i, form.rate.rate)" svgIcon="gds-feed-star"
                                      class="!fill-accent-orange !h-5"></mat-icon>
                            <mat-icon class="!h-5" *ngIf="!selectedRating(i, form.rate.rate)"
                                      svgIcon="gds-feed-star-empty"></mat-icon>
                        </ng-container>
                        <span>{{form.rate.rate}}/{{ratingArr.length}}</span>
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-4 w-full">
                <div *ngIf="hasContentToPublish() || this.isCurrentUser">
                    <div class="font-bold pb-1">{{'feed.contentTitle' | translate}}</div>
                    <div class="flex flex-col" *ngFor="let content of form.content">
                        <ng-container *ngIf="isDisplayed(content)">
                            <span class="text-primary-darkblue break-words pb-0.5">{{getLabel(content.key)}}</span>
                            <ng-container ngSwitch="{{getType(content.key)}}">
                                <div class="pb-4" *ngSwitchCase="'rate'" class="flex gap-2">
                                    <ng-container *ngFor="let rate of ratingArr; index as i">
                                        <mat-icon *ngIf="selectedRating(i, +content.value)" svgIcon="gds-feed-star"
                                                  class="!fill-accent-orange !h-5 "></mat-icon>
                                        <mat-icon *ngIf="!selectedRating(i, +content.value)"
                                                  svgIcon="gds-feed-star-empty" class="!h-5"></mat-icon>
                                    </ng-container>
                                </div>
                                <span class="pb-4" *ngSwitchCase="'number'">{{content.value}}/5</span>
                                <span class="pb-4" *ngSwitchCase="'date'">{{content.value  | date:'dd/MM/yyyy'}}</span>
                                <span class="pb-4" *ngSwitchDefault style="overflow-wrap: anywhere">{{getValue(content.value)}}</span>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
