<div class="w-full flex flex-col gap-1">
  <label class="text-black"
  >{{props.label}} <span *ngIf="props.required" class="text-primary-magenta">*</span></label>
  <mat-form-field
  >
  <input matInput [class.cdk-visually-hidden]="true" [formControl]="formControl" [formlyAttributes]="field">
  <gds-mat-star-rating
          [rating]="rating"
          [starCount]="starCount"
          (ratingUpdated)="onRatingChanged($event, formControl)">
  </gds-mat-star-rating>
    <mat-error *ngIf="formControl.hasError('required')">
      {{ 'error.field.required' | translate }}
    </mat-error>
  </mat-form-field>
</div>
