import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FieldType, FieldTypeConfig} from "@ngx-formly/core";
import {MatInput} from "@angular/material/input";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'gds-feed-form-rating-input-field',
  templateUrl: './feed-form-rating-input-field.component.html',
  styleUrl: './feed-form-rating-input-field.component.scss'
})
export class FeedFormRatingInputFieldComponent extends FieldType<FieldTypeConfig>{
  rating:number = 0;
  starCount:number = 5;

  onRatingChanged(rating: number, formControl: FormControl){
    formControl.setValue(rating);
    this.rating = rating;
  }
}
