import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserDto} from "@api/models/user-dto";
import {FormContentDto} from "@api/models/form-content-dto";
import {FormDetailDto} from "@api/models/form-detail-dto";
import {TranslateService} from "@ngx-translate/core";
import {TimeAgoPipe} from "ngx-pipes";
import {MatDialog} from "@angular/material/dialog";
import {UserDetailPopupViewComponent} from "@feed/components/user-detail-popup-view/user-detail-popup-view.component";
import {ConfirmDialogComponent, IAppUser, ToastService} from "@base";
import {FeedService} from "@feed/services/feed.service";
import {finalize, Observable, take} from "rxjs";

export const FEED_STAR_COUNT = 5

@Component({
    selector: 'gds-feed-log-entry',
    templateUrl: './feed-log-entry.component.html',
    styleUrl: './feed-log-entry.component.scss',
})
export class FeedLogEntryComponent implements OnInit {
    @Input({required: true}) form!: FormDetailDto;
    @Input({required: true}) user!: UserDto;
    @Input() currentUser!: IAppUser | null;
    @Input() availableDate!: Date;
    @Output() itemDeletedEvent = new EventEmitter<string>();
    isCurrentUser: boolean = false;
    public ratingArr: number[] = [];
    public keyLabelsMap: Map<string, string> = new Map<string, string>();

    constructor(
        public translateService: TranslateService,
        public dialog: MatDialog,
        private toastService: ToastService,
        private feedService: FeedService,
    ) {
    }

    ngOnInit(): void {
        this.isCurrentUser = this.form.user?.id == this.currentUser?.id
        for (let index = 0; index < FEED_STAR_COUNT; index++) {
            this.ratingArr.push(index);
        }
        this.form.formTemplate.defaultStructure?.forEach((formStruct) => {
            this.keyLabelsMap.set(formStruct.key, formStruct.props?.label!);
        });
        this.getNumberLabels();

    }

    getNumberLabels() {
        let formStruct = this.form.formTemplate.defaultStructure;
        if (!!formStruct) {
            let numberQuestions = formStruct.filter(form => {
                return this.isNumberQuestion(form.key);
            });
            numberQuestions.forEach((form) => {
                form.props?.options?.forEach((q, index) => {
                    let key = this.getNumberKey(form.key, index);
                    this.keyLabelsMap.set(key, q.label!);
                })
            })
        }
    }

    getNumberKey(formStructKey: string, index: number) {
        return formStructKey + "_" + index;
    }

    isNumberQuestion(key: string): boolean {
        return this.getType(key) === 'number';
    }

    getType(key: string): string {
        return key.split("_")[0];
    }

    isDisplayed(content: FormContentDto): boolean {
        return (this.isCurrentUser || content.publish)  && (content.value.length > 0);
    }

    hasContentToPublish(): boolean {
        return this.form.content.filter(value => value.publish).length > 0;
    }

    selectedRating(index: number, rating: any): boolean {
        return +rating >= index + 1;
    }

    getFeedInfo() {
        const agoPipe = new TimeAgoPipe();
        return this.translateService.instant('feed.info', {
            location: this.user.location ?? 'N/A',
            time: agoPipe.transform(this.availableDate)
        })
    }

    getLabel(key: string) {
        return this.keyLabelsMap.get(key) ?? '';
    }

    showInfo() {
        this.dialog.open(UserDetailPopupViewComponent, {data: this.user});
    }

    public openConfirmDialog(): Observable<boolean> {
        const title = this.translateService.instant('feed.deleteFormTitle', {
            formTemplateName: this.form.formTemplate.name
        });

        const content = this.translateService.instant('feed.deleteFormContent', {
            formTemplateName: this.form.formTemplate.name
        });

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {title, content}
        });

        return dialogRef.afterClosed().pipe(take(1));
    }

    isDeletable() {
        const date = new Date(this.form.createdDate!);
        date.setDate(date.getDate() + 2)
        return date >= new Date();

    }

    delete() {
        this.openConfirmDialog().subscribe(result => {
            if (result) {
                this.feedService.deleteFeedEntry(this.form)
                    .pipe(finalize(() => this.itemDeletedEvent.emit(this.form.id!))).subscribe();
            }
        })

    }

    getValue(value : any) {
        if(typeof value === "string" && !value.length){
            return "Not answered";
        }
        return value;
    }
}
