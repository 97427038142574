import {Component, Inject, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserDto} from "@api/models/user-dto";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BadgeDto} from "@api/models/badge-dto";

@Component({
  selector: 'gds-user-detail-popup-view',
  templateUrl: './user-detail-popup-view.component.html',
  styleUrl: './user-detail-popup-view.component.scss'
})
export class UserDetailPopupViewComponent {

  constructor(
      public dialogRef: MatDialogRef<UserDetailPopupViewComponent>,
      @Inject(MAT_DIALOG_DATA) public user: UserDto,

  ) {

  }
}
