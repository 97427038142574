<div class="w-full flex flex-col gap-1">
    <label class="text-black"
    >{{props.label}} <span *ngIf="props.required" class="text-primary-magenta">*</span></label>
    <mat-form-field
            color="primary"
            appearance="outline"
            floatLabel="always"
    >
        <mat-select
                (selectionChange)="updateAnotherOption($event, matSelect, matInput)"
                multiple="{{props['multiple']}}"
                [formControl]="formControl" [formlyAttributes]="field"
                placeholder="{{props.placeholder}}"
                #matSelect
        >
            <mat-select-trigger >
                {{ props['multiple'] ? selectTrigger : matSelect.value}}
            </mat-select-trigger>
            <mat-option *ngFor="let option of props['choices']" [value]="option.value">
                {{option.label}}
            </mat-option>
            <div [hidden]="!hasAnotherOption()">
                <mat-option [value]="matInput.value"
                            (click)="setDefaultValueOther(matSelect, matInput.value)"
                            *ngIf="hasAnotherOption()">{{ 'feed.modal.other' | translate }}</mat-option>
            </div>
        </mat-select>
        <mat-error *ngIf="formControl.hasError('required')">
            {{ 'error.field.required' | translate }}
        </mat-error>
    </mat-form-field>
    <label *ngIf="hasAnotherOption() && !isDisabledAndHidden(matSelect)" class="text-black">{{ 'feed.modal.specify' | translate }}</label>

    <div class="flex" [ngClass]="!hasAnotherOption()?'!hidden' : ''" >
        <mat-form-field
                [ngClass]="hasAnotherOption() && isDisabledAndHidden(matSelect) ?'!hidden' : ''"
                color="primary"
                appearance="outline"
                floatLabel="always"
                class="w-full"
        >
            <div>
                <input matInput class=" !border !border-solid disabled:bg-graytone-light" #matInput
                       [disabled]="isDisabledAndHidden(matSelect)"
                       (input)="updateAnotherOptionValue(matSelect, matInput)">

            </div>
        </mat-form-field>
    </div>

</div>
