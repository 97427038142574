<div class="w-full flex flex-col gap-1">
    <label class="text-black"
    >{{props.label}} <span *ngIf="props.required" class="text-primary-magenta">*</span></label>
    <mat-form-field
            color="primary"
            appearance="outline"
            floatLabel="always"
    >
    <input
            min="0"
            class="h-20"
            matInput
            cdkTextareaAutosize
            type="number"
            [formControl]="formControl" [formlyAttributes]="field"
            placeholder="{{props.placeholder}}"
    >
        <mat-error *ngIf="formControl.hasError('required')">
            {{ 'error.field.required' | translate }}
        </mat-error>
    </mat-form-field>
</div>
