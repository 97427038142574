<div class="modal modal-lg flex flex-col gap-2 !px-0 md:!px-14 !py-2 md:!py-6">
    <h1 class="!font-bold text-3xl flex !px-5 !pt-4 !pb-2 justify-center">
        {{ 'feed.modal.newLogEntry'| translate }}
    </h1>
    <form
            [formGroup]="formGroup"
            (submit)="submit()"
    >
        <div
                mat-dialog-content
                class="!px-5 !py-1"
        >
            <div class="grid !grid-cols-1 gap-1 gap-x-1">
                <div class="w-full flex flex-col gap-1">
                    <label class="text-black"
                    >{{ 'feed.modal.topic' | translate }} <span class="text-primary-magenta">*</span></label>
                    <mat-form-field
                            color="primary"
                            appearance="outline"
                            floatLabel="always"
                    >
                        <mat-select [errorStateMatcher]="matcher"
                                    [formControl]="formGroup.controls.badge"
                                    [placeholder]="'feed.modal.placeholder.topic' | translate"
                                    (valueChange)="badgeValueChange()"
                                    #badge
                        >
                            <mat-option *ngFor="let badge of formMap.keys()" [value]=badge>
                                {{badge.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.badge.hasError('required')">
                            {{ 'error.field.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="w-full flex flex-col gap-1">
                    <label class="text-black"
                    >{{ 'feed.modal.form' | translate }} <span class="text-primary-magenta">*</span></label
                    >
                    <mat-form-field
                            color="primary"
                            appearance="outline"
                            floatLabel="always"
                    >
                        <mat-select [errorStateMatcher]="matcher"
                                    [formControl]="formGroup.controls.form"
                                    [placeholder]="'feed.modal.placeholder.form' | translate"
                                    #form
                                    (valueChange)="showForm(form.value)"
                        >
                            <mat-option *ngFor="let form of formMap.get(badge.value)" [value]=form>
                                {{form.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.form.hasError('required')">
                            {{ 'error.field.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="w-full flex flex-col gap-1"
                     *ngIf="!!badge.value && formMapProvider.get(badge.value)!.length != 0"
                >
                    <label class="text-black"
                    >{{ 'feed.modal.provider' | translate }}</label
                    >
                    <mat-form-field
                            color="primary"
                            appearance="outline"
                            floatLabel="always"
                    >
                        <mat-select
                                [errorStateMatcher]="matcher"
                                [formControl]="formGroup.controls.provider"
                                [placeholder]="'feed.modal.placeholder.provider' | translate"
                        >

                            <ng-container *ngIf="!!badge.value">
                                <mat-option *ngFor="let provider of formMapProvider.get(badge.value)" [value]=provider>
                                    {{provider}}
                                </mat-option>
                            </ng-container>

                        </mat-select>

                        <mat-error
                                *ngIf="(!badge.value || !!badge.value) && formGroup.controls.provider.hasError('required')">
                            {{ 'error.field.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <formly-form [model]="models" [fields]="formFieldConfig"
                             [form]="formGroup">
                </formly-form>

            </div>
        </div>
        <div class="px-5">
            {{ 'feed.modal.note' | translate }}
        </div>
        <div
                mat-dialog-actions
                class="flex gap-4 !justify-center !px-5 !pb-4 !pt-2"
        >
            <button
                    type="button"
                    mat-raised-button
                    class="!rounded-3xl"
                    (click)="close()"
                    color="accent"
            >
                {{ 'common.modal.cancel' | translate }}
            </button>
            <button
                    mat-raised-button
                    cdkFocusInitial
                    class="!rounded-3xl"
                    color="primary"
                    type="submit"
            >
                {{ 'common.modal.send' | translate }}
            </button>
        </div>

    </form>
    <ng-template #loading>
        <div class="flex flex-col gap-6 items-center justify-center">
            <mat-spinner
                    strokeWidth="4"
                    class="!w-20 !h-20"
            ></mat-spinner>
        </div>
    </ng-template>
</div>
