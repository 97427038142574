import {Component, OnInit} from '@angular/core';
import {BadgeDto} from "@api/models/badge-dto";
import {BadgeService} from "@admin/services";
import {FeedService} from "@feed/services/feed.service";
import {FormControl, FormGroup} from "@angular/forms";
import {finalize} from "rxjs";
import {FeedDto} from "@api/models/feed-dto";
import {AnnouncementDto} from "@api/models/announcement-dto";

export interface IFeedsFilter {
    isLatest: boolean,
    isMyPosts: boolean,
    badgeId: string,
    pageNumber: number,
}

export const TRUE = '1';
export const FALSE = '0';

@Component({
    selector: 'gds-feed-dashboard',
    templateUrl: './feed-dashboard.component.html',
    styleUrl: './feed-dashboard.component.scss',
    providers: [BadgeService, FeedService]
})
export class FeedDashboardComponent implements OnInit {
    loadingFeeds = false;
    loadingMore = false;
    isLastPage = false;
    feeds: FeedDto[] = [];
    pageSize: number = 15;

    eventReload : boolean = false;

    announcements: AnnouncementDto[] = [];
    filterForm = new FormGroup({
        isLatest: new FormControl(TRUE),
        isMyPosts: new FormControl(TRUE),
        badgeId: new FormControl(''),
        pageNumber: new FormControl(0),
    })
    badges: BadgeDto[] = [];
    filter: IFeedsFilter = {
        isLatest: true,
        isMyPosts: true,
        badgeId: '',
        pageNumber: 0
    };

    constructor(
        public badgeService: BadgeService,
        public feedService: FeedService,
    ) {
        this.badgeService.getBadges().subscribe((badges) => {
            this.badges = badges;
        });
        this.getFeeds();
    }

    getFeeds(): void {
        this.loadingFeeds = true;
        this.feedService.getFeeds(this.filter).pipe(
            finalize(() => (this.loadingFeeds = false))).subscribe(
            {
                next: (feeds) => {
                    this.isLastPage = feeds.length < this.pageSize;
                    this.feeds = feeds;
                }
            }
        );
    }

    updateIsLatest() {
        this.filterForm.controls.isLatest.setValue(this.isLatest() ? FALSE : TRUE);
        this.updateFilter();
    }

    updateFilter() {
        this.filter = {
            isLatest: this.filterForm.value.isLatest! === TRUE,
            isMyPosts: this.filterForm.value.isMyPosts! === TRUE,
            badgeId: this.filterForm.value.badgeId!,
            pageNumber: this.filterForm.value.pageNumber!,
        };
        this.getFeeds();
    }

    isLatest() {
        return this.filterForm.controls.isLatest.value === TRUE;
    }

    ngOnInit(): void {
    }

    openFeedbackMail() {
        window.location.href = "mailto:expedition@gesundheitsdatenraum.ch?subject=%5BGDS%20Platform%20Feedback%5D";
    }

    loadMore() {
        this.filter.pageNumber = this.filter.pageNumber += 1
        this.loadingMore = true;
        this.feedService.getFeeds(this.filter).pipe(
            finalize(() => this.loadingMore = false)).subscribe(
            {
                next: (feeds) => {
                    this.isLastPage = feeds.length < this.pageSize;
                    this.feeds.push(...feeds);
                }
            }
        );

    }

    updateFeeds(formId: string) {
        this.eventReload = true;
        const index = this.feeds.indexOf(this.feeds.find(value => value.formDto?.id == formId)!);
        this.feeds.splice(index, 1);
    }

    progressBarLoaded() {
        this.eventReload = false;
    }
}
