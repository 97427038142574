import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'gds-mat-star-rating',
  templateUrl: './mat-star-rating.component.html',
  styleUrl: './mat-star-rating.component.scss',
  encapsulation: ViewEncapsulation.Emulated
})
export class MatStarRatingComponent implements OnInit {

  @Input('rating') public rating: number = 0;
  @Input('starCount') public starCount: number = 5;
  @Output() public ratingUpdated = new EventEmitter();

  public ratingArr: number[] = [];

  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }
  onClick(rating:number) {
    this.ratingUpdated.emit(rating);
    return false;
  }

  selectedRating(index: number): boolean {
    return this.rating >= index + 1;
  }
}

