<mat-card style="background:linear-gradient(275deg,#3B3B7F 0%,#2E409B 22%,#0052FF 100%);">
  <mat-card-content>
    <div class="flex gap-4 flex-col">
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center cursor-pointer" (click)="showInfo()">
          <img
                  [src]="'./assets/announcement-icon.png'"
                  alt="Avatar"
                  class="w-14 h-14 rounded-full"
          />
          <span class="font-bold text-white">{{announcement.title}}</span>
        </div>
        <div>
          <span class="text-white">{{availableDate| timeAgo}}</span>
        </div>
      </div>

      <span class="break-words text-white" [innerHTML]="changeToLinks(announcement.content)" ></span>

    </div>
  </mat-card-content>
</mat-card>
