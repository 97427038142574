<div class="grid !grid-cols-1 gap-1 gap-x-1 ">
    <div class="w-full flex flex-col gap-1">
        <label class="text-black">{{props.label}}</label>
        <mat-form-field
                color="primary"
                appearance="outline"
                floatLabel="always"
        >

            <input matInput
                   [placeholder]="'admin.announcement.placeholder.publicationDate' | translate"
                   [formControl]="formControl"
                   [matDatepicker]="picker"
                   [formlyAttributes]="field"
            >
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

            <mat-error *ngIf="formControl.hasError('required') || formControl.hasError('dateFormat')">
                {{ 'error.field.dateFormat' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
</div>
