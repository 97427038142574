import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FieldType, FieldTypeConfig} from "@ngx-formly/core";

@Component({
  selector: 'gds-feed-form-input-number-input-field',
  templateUrl: './feed-form-input-number-input-field.component.html',
  styleUrl: './feed-form-input-number-input-field.component.scss'
})
export class FeedFormInputNumberInputFieldComponent extends FieldType<FieldTypeConfig> {

}
