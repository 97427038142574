<div>
	<div class="example-container h-full flex flex-col md:flex-row gap-6 md:gap-4 justify-center">
		<div class="2xl:pl-4 w-auto min-w-[300px] md:max-w-[400px] md:pl-8">
					<gds-feed-progress-bar (progressBarEvent) = "progressBarLoaded()" [eventReload]="eventReload" ></gds-feed-progress-bar>
		</div>
		<div class=" flex-1 flex justify-center lg:w-2/3 w-full max-w-5xl">
			<div class="flex flex-col p-0.5 md:p-0 gap-4 w-full h-full">
				<div class="flex flex-col justify-between md:items-center md:flex-row gap-4 md:gap-0">
					<h1 class="!font-bold !text-primary-darkblue">{{ 'feed.title' | translate }}</h1>
					<form [formGroup]="filterForm" class="flex gap-2 md:gap-4 h-7 ">
						<div class="w-1/4 md:!w-40">
							<mat-select
									hideSingleSelectionIndicator
									[value]="filterForm.controls.badgeId.value"
									(selectionChange)="updateFilter()"
									[formControl]="filterForm.controls.badgeId"
									class="!bg-transparent !border-2 !border-primary-darkblue !text-primary-darkblue !rounded !px-2">
								<mat-option [value]="''">{{'feed.filter.allTopic' | translate}}</mat-option>
								<mat-option *ngFor="let badge of badges" [value]="badge.id">
									{{badge.name}}
								</mat-option>
							</mat-select>
						</div>

						<mat-button-toggle-group
								class="!h-full !text-sm !p-0 !border-2 !border-primary-darkblue rounded"
								[value]="filterForm.controls.isMyPosts.value"
								[formControl]="filterForm.controls.isMyPosts"
								(change)="updateFilter()">
							<mat-button-toggle [value]="'0'">{{'feed.filter.allPosts' | translate}}</mat-button-toggle>
							<mat-button-toggle [value]="'1'">{{'feed.filter.myPosts' | translate}}</mat-button-toggle>
						</mat-button-toggle-group>

						<div class="w-1/5 md:w-auto!text-sm">
							<button
									(click)="updateIsLatest()"
									mat-button
									class="!bg-transparent !text-primary-darkblue !h-full">
								<span *ngIf="isLatest()">{{'feed.filter.latest' | translate}}</span>
								<mat-icon class="!w-3.5" *ngIf="isLatest()" svgIcon="gds-down"></mat-icon>
								<span *ngIf="!isLatest()">{{'feed.filter.oldest' | translate}}</span>
								<mat-icon *ngIf="!isLatest()" svgIcon="gds-down" class="!rotate-180 !w-3.5"></mat-icon>
							</button>
						</div>
					</form>
				</div>
				<div class="flex flex-col justify-center">
					<gds-feed-entries (itemDeletedEvent)="updateFeeds($event)" *ngIf="!loadingFeeds; else loading" [announcements]="announcements" [feeds]="feeds">
					</gds-feed-entries>
					<button class="bg-primary-darkblue w-28 h-9 self-center rounded-[22px] text-white m-4" [hidden]="loadingFeeds || isLastPage" *ngIf="!loadingMore; else loading" (click)="loadMore()">{{ 'feed.loadMore' | translate }}</button>
				</div>
			</div>
		</div>


	</div>

	<ng-template #loading>
		<div class="flex flex-col gap-6 items-center justify-center mt-4 ">
			<mat-spinner
					strokeWidth="4"
					class="!w-20 !h-20"
			></mat-spinner>
		</div>
	</ng-template>
	<!-- Pin to bottom right corner -->
	<div class="fixed bottom-20 -right-8 h-8 w-24 rotate-90">
		<button (click)="openFeedbackMail()" mat-raised-button
				class="!h-full !w-full !bg-accent-purple rotate-180 rounded-t-lg !font-extrabold !text-graytone-light">
			Feedback
		</button>
	</div>
</div>
