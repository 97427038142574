<div class="w-full flex flex-col gap-1">
  <label class="text-black"
  >{{props.label}} <span *ngIf="props.required" class="text-primary-magenta">*</span></label>
  <mat-form-field
          color="primary"
          appearance="outline"
          floatLabel="always"
  >
    <textarea
            class="h-20"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            [formControl]="formControl" [formlyAttributes]="field"
            placeholder="{{props.placeholder}}"
    ></textarea>
    <mat-error *ngIf="formControl.hasError('required')">
      {{ 'error.field.required' | translate }}
    </mat-error>
  </mat-form-field>
</div>
