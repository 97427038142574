import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators} from "@angular/forms";
import {GdsErrorStateMatcher} from "../../../Base/models";
import {FormTemplateService} from "../../../Admin/services/form-template.service";
import {BadgeDto} from "../../../../api/models/badge-dto";
import {FormTemplateDto} from "../../../../api/models/form-template-dto";
import {
  FormContentDto,
  FormDto,
  FormStructDto,
  FormTemplateStateDto,

} from "../../../../api/models";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {FeedFormInputFieldComponent} from "@feed/components";
import {FeedFormSelectInputFieldComponent} from "@feed/components";
import {FeedFormNumberInputFieldComponent} from "@feed/components";
import {FeedFormRatingInputFieldComponent} from "@feed/components";
import {AuthService} from "@base";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FeedService} from "../../services/feed.service";
import {MatSelect} from "@angular/material/select";
import {TranslateService} from "@ngx-translate/core";
import {resetParseTemplateAsSourceFileForTest} from "@angular/compiler-cli/src/ngtsc/typecheck/diagnostics";
import {
  FeedFormDateInputFieldComponent
} from "@feed/components/feed-form-date-input-field/feed-form-date-input-field.component";
import {
  FeedFormInputNumberInputFieldComponent
} from "@feed/components/feed-form-input-number-input-field/feed-form-input-number-input-field.component";
import {GdsValidator} from "../../../Base/common/gds-validator";

export const STARTED = "Started"
export const TERMINATED = "Terminated"
export const EMPTY = "Empty"
export const OVERALL_RATE_KEY = "overallRate"
export const OVERALL_RATE_COMMENT_KEY = "overallRateComment"

@Component({
  selector: 'gds-feed-entry-dialog',
  templateUrl: './feed-entry-dialog.component.html',
  styleUrl: './feed-entry-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormTemplateService, FeedService]
})
export class FeedEntryDialogComponent implements OnInit {
  formGroup = new FormGroup({
    provider: new FormControl(undefined, ),
    badge: new FormControl(undefined ,  [Validators.required]),
    form: new FormControl(undefined , [Validators.required]),
  });
  formMap: Map<BadgeDto, Array<FormTemplateDto>> = new Map<BadgeDto, Array<FormTemplateDto>>();
  formMapProvider: Map<BadgeDto,  String[]> = new Map<BadgeDto, String[]>();
  matcher = new GdsErrorStateMatcher();
  providers: String[] =[];
  badgeDtos: BadgeDto[] =[];
  publishMap: Map<string, boolean> = new Map<string, boolean>();
  models: any;
  formFieldConfig: FormlyFieldConfig[] = [];
  formDto: FormDto = {
    id: '',
    createdDate: '',
    rate: {},
    provider: '',
    userId: '',
    formTemplateId: '',
    content: new Array<FormContentDto>()
  };

  constructor(
      public dialogRef: MatDialogRef<FeedEntryDialogComponent>,
      public formTemplateService: FormTemplateService,
      public feedService: FeedService,
      public authService: AuthService,
      public translateService: TranslateService,
      @Inject(MAT_DIALOG_DATA) public data: { badge: BadgeDto, formtemplate: FormTemplateStateDto }

  ) {
    this.formTemplateService
        .getFormTemplates()
        .subscribe({
              next: (form) => {
                form.forEach(value => {
                  let badge = value['badge'];
                  this.badgeDtos.push(badge!)
                  let formList = value['listFormTemplate'];
                  if (!!badge && !!formList) {
                    this.formMap.set(badge, formList);
                    this.formMapProvider.set(badge,badge.providers!)
                    this.providers = badge.providers!;
                  }
                });
                if (data) {
                    let badge = this.badgeDtos.find(value => value.id == this.data.badge.id)!
                    let formlist = this.formMap.get(badge)!!
                    let matchedForm = formlist.find(value => value.id == this.data.formtemplate.id)!
                    this.formGroup.controls.badge.setValue(badge as any);
                    this.formGroup.controls.form.setValue(matchedForm as any);
                    this.showForm(matchedForm)
                }
              }
            }
        );
  }

  ngOnInit() {

  }



  resetFormGroup() {
    this.formFieldConfig = [];
    const keysToRemove: string[] = Object.keys(this.formGroup.controls).filter(key => key !== 'badge' && key !== 'provider' && key !== 'form');
    // https://github.com/angular/angular/issues/47306 - because angular cannot recognize added form controls
    // @ts-ignore
    keysToRemove.forEach((key) => {this.formGroup.removeControl(key)});
  }


  badgeValueChange() {
    this.resetFormGroup();
    this.formGroup.controls.provider.setValue(null);
    this.formGroup.controls.form.setValue(null);
    this.formGroup.controls.form.setErrors(Validators.required(this.formGroup.controls.form));
  }


  showForm(formTemplate: FormTemplateDto) {
    this.resetFormGroup();
    formTemplate.defaultStructure?.forEach(form => {
      switch (form.type) {
        case 'textQuestion': {
          this.formFieldConfig = [...this.formFieldConfig, this.textInput(form)]
          break;
        }
        case 'multipleOptionQuestion': {
          this.formFieldConfig = [...this.formFieldConfig, this.selectInput(form)];
          break;
        }
        case 'dropdownListQuestion': {
          this.formFieldConfig = [...this.formFieldConfig, this.selectInput(form)];
          break;
        }
        case 'numberQuestion': {
          this.formFieldConfig = [...this.formFieldConfig, ...this.numberInputs(form)];
          break;
        }
        case 'rateQuestion': {
          this.formFieldConfig = [...this.formFieldConfig, this.ratingInput(form)];
          break;
        }
        case 'dateQuestion': {
          this.formFieldConfig = [...this.formFieldConfig, this.dateInput(form)];
          break;
        }
        case 'inputNumberQuestion': {
          this.formFieldConfig = [...this.formFieldConfig, this.inputNumberInput(form)];
          break;
        }
      }
    });
    this.formFieldConfig = [...this.formFieldConfig, this.overallRateCommentInput()];
    this.formFieldConfig = [...this.formFieldConfig, this.overallRateInput()];
  }

  overallRateInput(): FormlyFieldConfig {
    this.publishMap.set(OVERALL_RATE_KEY, true);
    return {
      key: OVERALL_RATE_KEY,
      type: FeedFormRatingInputFieldComponent,
      props: {
        label: this.translateService.instant('feed.modal.overallRate'),
        required: true,
      }
    }
  }

  overallRateCommentInput(): FormlyFieldConfig {
    this.publishMap.set(OVERALL_RATE_COMMENT_KEY, true);
    return {
      key: OVERALL_RATE_COMMENT_KEY,
      type: FeedFormInputFieldComponent,
      props: {
        label: this.translateService.instant('feed.modal.overallRateComment'),
        placeholder: this.translateService.instant('feed.modal.placeholder.text'),
        required: true,
      }
    }
  }

  textInput(formStruct: FormStructDto): FormlyFieldConfig {
    this.publishMap.set(formStruct.key, formStruct.publish);
    return {
      key: formStruct.key,
      type: FeedFormInputFieldComponent,
      props: {
        disabled: formStruct.props?.disabled,
        label: formStruct.props?.label,
        placeholder: formStruct.props?.placeholder,
        required: formStruct.props?.required,
      }
    }
  }

  selectInput(formStruct: FormStructDto): FormlyFieldConfig {
    this.publishMap.set(formStruct.key, formStruct.publish);
    return {
      key: formStruct.key,
      type: FeedFormSelectInputFieldComponent,
      props: {
        disabled: formStruct.props?.disabled,
        label: formStruct.props?.label,
        placeholder: formStruct.props?.placeholder,
        required: formStruct.props?.required,
        choices: formStruct.props?.options,
        multiple: formStruct.props?.multiple,
        hasAnotherOption: formStruct.props?.hasAnotherOption,
        other : this.translateService.instant('feed.modal.other'),
      }
    }
  }

  numberInputs(formStruct: FormStructDto): FormlyFieldConfig[] {
    let result: FormlyFieldConfig[] = [];
    let formLength = formStruct.props?.options?.length;
    if (!!formLength) {
      for (let i = 0; i < formLength; i++) {
        let key = formStruct.key + "_" + i;
        this.publishMap.set(key, formStruct.publish);
        result = [...result, {
          key: key,
          type: FeedFormNumberInputFieldComponent,
          props: {
            disabled: formStruct.props?.disabled,
            required: formStruct.props?.required,
            label: formStruct.props?.options?.[i].label,
          },
          expressions: {
            'props.parentQuestion': () => {
              if (i === 0) {
                return formStruct.props?.label;
              }
              return '';
            }
          }
        }];
      }
    }
    return result;
  }

  ratingInput(formStruct: FormStructDto): FormlyFieldConfig {
    this.publishMap.set(formStruct.key, formStruct.publish);
    return {
      key: formStruct.key,
      type: FeedFormRatingInputFieldComponent,
      props: {
        disabled: formStruct.props?.disabled,
        label: formStruct.props?.label,
        required: formStruct.props?.required,
      }
    }
  }
  dateInput(formStruct: FormStructDto): FormlyFieldConfig {
    this.publishMap.set(formStruct.key, formStruct.publish);
    return {
      key: formStruct.key,
      type: FeedFormDateInputFieldComponent,
      props: {
        disabled: formStruct.props?.disabled,
        label: formStruct.props?.label,
        required: formStruct.props?.required,
      },
      validators : {
        validation : [GdsValidator.matchFormatValidator]
      }
    }
  }

  inputNumberInput(formStruct: FormStructDto): FormlyFieldConfig {
    this.publishMap.set(formStruct.key, formStruct.publish);
    return {
      key: formStruct.key,
      type: FeedFormInputNumberInputFieldComponent,
      props: {
        disabled: formStruct.props?.disabled,
        label: formStruct.props?.label,
        required: formStruct.props?.required,
        placeholder: this.translateService.instant('feed.modal.placeholder.number'),

      }
    }
  }

  submit() {
    let formTemplate = this.formGroup.controls['form'].value;
    let provider = this.formGroup.controls['provider'].value;
    let badge = this.formGroup.controls['badge'].value;
    // @ts-ignore
    let rate = this.formGroup.controls[OVERALL_RATE_KEY].value;
    // @ts-ignore
    let rateComment = this.formGroup.controls[OVERALL_RATE_COMMENT_KEY].value;
    let formContents: FormContentDto[] = [];
    Object.entries(this.formGroup.controls).forEach(entry => {
      let key = entry[0];
      let value: any = entry[1]['value'];
      let publish = this.publishMap.get(key);
      let stringVal = '';
      if (!!value) {
        stringVal = value.toString();
      }
      else{
        stringVal = '';
      }
      if (!!key && publish !== undefined) {
        if (key !== 'badge' && key !== 'provider' && key !== 'form' && key !== OVERALL_RATE_KEY && key !== OVERALL_RATE_COMMENT_KEY) {
          formContents.push({
            key: key,
            value: stringVal,
            publish: publish
          });
        }
      }
    });

    Object.values(this.formGroup.controls).forEach((control) => {
      control.markAllAsTouched();
    })
    if (this.formGroup.invalid) {
      return;
    }

    this.authService.getCurrentUser().subscribe(
        (user) => {
          if (!!user) {
            let userId = user['id'];
            if (!!userId) {
              if (!!formTemplate && !!badge && !!rate && !!rateComment) {
                this.formDto = {
                  userId: userId,
                  formTemplateId: formTemplate['id'],
                  rate: {rate: rate, comment: rateComment},
                  provider: provider??"",
                  content: formContents
                };
              }
            }
          }
          this.dialogRef.close(this.formDto);
        }
    )
  }


  close() {
    this.dialogRef.close();
  }
}
