<div
        class="modal modal-xxl flex gap-x-5 !p-8 !max-w-6xl !min-w-fit"
>
    <img
            [src]="user.picture??'./assets/default_avatar.png'"
            class="object-cover !w-32 !h-32 border-2 border-graytone rounded-full"
    />
    <mat-card class="!w-11/12">
      <mat-card-content class="!p-6">
        <div class="flex flex-col gap-4">
          <div class="flex justify-between items-center">
            <h1 class="!font-bold">{{ user.firstName + ' ' + user.lastName }}</h1>
          </div>
          <div class="flex gap-4 items-center">
            <mat-icon svgIcon="gds-linkedin"></mat-icon>
            <a
                    *ngIf="!!user.linkProfileLinkedin"
                    [href]="user.linkProfileLinkedin"
                    target="_blank"
                    class="underline text-accent-blue"
            >{{ user.firstName + ' ' + user.lastName }}
            </a>
          </div>
          <div class="!font-bold">
            {{ 'user.fromLocation' | translate: { location: user.location??'N/A', language: user.language } }}
          </div>
          <div class="flex flex-col gap-2">
            <h4 class="text-primary-darkblue !font-bold">{{ 'user.aboutMe' | translate }}</h4>
            <div>{{ user.about }}</div>
          </div>
          <div class="flex flex-col gap-2">
            <h4 class="text-primary-darkblue !font-bold">{{ 'user.platformReason' | translate }}</h4>
            <div>{{ user.platformReason }}</div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
</div>
