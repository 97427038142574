import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FeedService} from "../../services/feed.service";
import {FeedDto} from "@api/models/feed-dto";
import {UserService} from "@admin/services";
import {AnnouncementDto} from "@api/models/announcement-dto";
import {AuthService} from "@base";

@Component({
    selector: 'gds-feed-entries',
    templateUrl: './feed-entries.component.html',
    styleUrl: './feed-entries.component.scss',
    providers: [FeedService, UserService]
})
export class FeedEntriesComponent implements OnInit {

    @Input() feeds: FeedDto[] = [];
    @Input() announcements: AnnouncementDto[] = [];
    @Output() itemDeletedEvent = new EventEmitter<string>();


    constructor(public authService: AuthService,
    ) {
    }

    ngOnInit() {
    }

    isAnnouncement(feed: FeedDto): boolean {
        return !!feed.announcementDto;
    }

    groupBy<K, V>(array: V[], grouper: (item: V) => K) {
        return array.reduce((store, item) => {
            var key = grouper(item)
            if (!!key) {
                if (!store.has(key)) {
                    store.set(key, [item]);
                } else {
                    let value = store.get(key)
                    if (!!value) {
                        value.push(item);
                    }
                }
            }
            return store
        }, new Map<K, V[]>())
    }

    getAvailableDate(feed: FeedDto): any {
        return new Date(feed.availableDate!)
    }

    getAnnouncement(feed: FeedDto): any {
        return feed.announcementDto;
    }

    getForm(feed: FeedDto): any {
        return feed.formDto;
    }

    getUser(feed: FeedDto) {
        return feed.formDto?.user!;
    }

    deleteItem(formId: string) {
        this.itemDeletedEvent.emit(formId);
    }
}
