import {Component} from '@angular/core';
import {FieldType, FieldTypeConfig} from "@ngx-formly/core";
import {MatSelect} from "@angular/material/select";

@Component({
    selector: 'gds-feed-form-select-input-field',
    templateUrl: './feed-form-select-input-field.component.html',
    styleUrl: './feed-form-select-input-field.component.scss'
})
export class FeedFormSelectInputFieldComponent extends FieldType<FieldTypeConfig> {

    selectTrigger: string[] = [];

    updateAnotherOptionValue(matSelect: MatSelect, matInput: any) {
        let value = (matInput as HTMLInputElement).value;
        if (matSelect.options.last.selected && !!value) {
            this.setAnotherOptionValue(matSelect, value);
        }
    }

    updateAnotherOption(event: any, matSelect: MatSelect, matInput: HTMLInputElement) {
        let value = (matInput as HTMLInputElement).value
        if (matSelect.options.last.selected && this.hasAnotherOption()) {
            this.setAnotherOptionValue(matSelect, value);
        } else {
            this.selectTrigger = [...matSelect.value];
        }
    }

    setAnotherOptionValue(matSelect: MatSelect, value: string) {
        if ((matSelect.value as String []).length > 0) {
            (matSelect.value as String []).pop();
            (matSelect.value as String []).push(value);
        }
        this.selectTrigger = [...matSelect.value];
    }

    hasAnotherOption(): boolean {
        return this.props['hasAnotherOption'];
    }

    isDisabledAndHidden(matSelect: MatSelect) {
        if (matSelect.options) {
            return !matSelect.options.last.selected;
        }
        return false;
    }

    setDefaultValueOther(matSelect: MatSelect, value: string) {
        if (!this.isDisabledAndHidden(matSelect) && !value) {
            (matSelect.value as String []).pop();
            (matSelect.value as String []).push(this.props['other']);
            this.selectTrigger = [...matSelect.value];
        }
    }
}
