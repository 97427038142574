import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FieldType, FieldTypeConfig} from "@ngx-formly/core";

@Component({
  selector: 'gds-feed-form-number-input-field',
  templateUrl: './feed-form-number-input-field.component.html',
  styleUrl: './feed-form-number-input-field.component.scss'
})
export class FeedFormNumberInputFieldComponent extends FieldType<FieldTypeConfig>{
  numbers: number[] = [1,2,3,4,5];
}
