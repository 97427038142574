import {Injectable} from '@angular/core';
import {Model, ToastService} from '@base';
import {catchError, Observable, tap, throwError} from 'rxjs';
import {FeedControllerService} from "@api/services/feed-controller.service";
import {FeedDto} from "@api/models/feed-dto";
import {IFeedsFilter} from "@feed/components";
import {BadgeProgressBarDto} from '@api/models/badge-progress-bar-dto';
import {FormDto} from "@api/models/form-dto";
import {TranslateService} from "@ngx-translate/core";
import {FormDetailDto} from "@api/models/form-detail-dto";

@Injectable()
export class FeedService {
    private feedsModel = Model.create<FeedDto[]>([]);
    private progressBarModel = Model.create<BadgeProgressBarDto[]>([]);
    public readonly feeds$ = this.feedsModel.data$;
    public readonly progressBar$ = this.progressBarModel.data$;


    constructor(
        private feedControllerService: FeedControllerService,
        private toastService: ToastService,
        private translateService: TranslateService,
    ) {

    }

    public getFeeds(filter: IFeedsFilter): Observable<FeedDto[]> {
        return this.feedControllerService.getAllFeeds({
            isLatest: filter.isLatest,
            isMyPosts: filter.isMyPosts,
            badgeId: filter.badgeId,
            pageNumber: filter.pageNumber
        }).pipe(tap((data) => this.feedsModel.set(data)))
    }

    public getProgressBar(): Observable<BadgeProgressBarDto[]> {
        return this.feedControllerService.getBadgeProgressBar()
            .pipe(tap((data) => this.progressBarModel.set(data)))
    }

    public createFeedEntry(formDto: FormDto): Observable<string> {
        return this.feedControllerService.createFeed({body: formDto}).pipe(
            tap(() => {
                this.toastService.showSuccessToast(this.translateService.instant('toast.newFeedEntrySuccess'));
            }),
            catchError((error) => {
                this.toastService.showErrorToast(this.translateService.instant('toast.newFeedEntryFailed'));
                return throwError(error);
            })
        );
    }

    public deleteFeedEntry(formDetail : FormDetailDto): Observable<string> {
        return this.feedControllerService.deleteFeed({id: formDetail.id!}).pipe(
            tap(() => {
                this.toastService.showSuccessToast(this.translateService.instant('toast.deleteFormSuccess'));
            }),
            catchError((error) => {
                this.toastService.showErrorToast(this.translateService.instant('toast.deleteFormError'));
                return throwError(error);
            })
        );
    }
}
